import log from "./src/helpers/logger";

log.debug("Starting DMC...");
if (process.env.NODE_ENV !== "development") {
  var imported = document.createElement('script');
  imported.src = `${process.env.REACT_APP_ASSETS}/static/js/main.js?v=${process.env.REACT_APP_Version}`;
  document.head.appendChild(imported);

  imported = document.createElement("script");
  imported.src = `${process.env.REACT_APP_ASSETS}/scripts/dt-rum.js`;
  document.head.appendChild(imported);

  log.debug("Injected DMC JS...");

  window.addEventListener('load',
    function() {
      const container = document.getElementById("dmcWidget");
      const platform = container.getAttribute("data-platform");
      log.debug("Starting for:" + platform);
      window.startDMC({
        platform: platform
      });
    });
}
